@import url(https://fonts.googleapis.com/css?family=Give+You+Glory|The+Girl+Next+Door|Gloria+Hallelujah|Indie+Flower);

.sticky-note {
  -webkit-box-shadow: #DDD 0px 1px 2px;
  position: relative;
  background-color: #F4F39E;
  border-color: #DEE184;
  color: #47576B;
  text-align: center;
  margin: 2.5em 0px;
  padding: 1.5em 1em;
  -webkit-box-shadow: 0px 1px 3px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 1px 3px rgba(0,0,0,0.25);
  box-shadow: 0px 1px 3px rgba(0,0,0,0.25);
  width: 250px;
  font-family: 'Indie Flower', cursive;
  font-family: 'Give You Glory', cursive;
  font-family: 'The Girl Next Door', cursive;
  font-family: 'Gloria Hallelujah', cursive;
}
.post-it {
  display: table;
  margin: 1em auto 0;  
}
.taped {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.sticky-note.taped:after {
  display: block;
  content: "";
  position: absolute; 
  width: 110px;
  height: 30px;
  top: -21px;
  transform: rotate(-10deg);
  left: 30%;    
  border: 1px solid #fff;
  background: rgba(254, 254, 254, .6);
  -webkit-box-shadow: 0px 0 3px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0 3px rgba(0,0,0,0.1);
  box-shadow: 0px 0 3px rgba(0,0,0,0.1);  
}

.note { 
-webkit-box-shadow: #DDD 0px 1px 2px;
  position: relative;
  background-color: #F4F39E;
  border-color: #DEE184;
  text-align: center;
  margin: 1.5em auto;
  padding: 1.5em 1em;
  -webkit-box-shadow: 0px 1px 3px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 1px 3px rgba(0,0,0,0.25);
  box-shadow: 0px 1px 3px rgba(0,0,0,0.25);
  -webkit-transform: rotate(2deg);
  -moz-transform: rotate(2deg);
  -o-transform: rotate(2deg);
  -ms-transform: rotate(2deg);
  transform: rotate(2deg);
  width: 250px;
  font-family: 'The Girl Next Door', cursive; /*originally with brain flower font*/
  font-size: 1em;
}
.note:after {
  display: block;
  content: "";
  position: absolute; 
  width: 110px;
  height: 30px;
  top: -21px;
  left: 30%;    
  border: 1px solid #fff;
  background: rgba(254, 254, 254, .6);
  -webkit-box-shadow: 0px 0 3px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0 3px rgba(0,0,0,0.1);
  box-shadow: 0px 0 3px rgba(0,0,0,0.1);  
}

@media (max-width: 600px) {
  .sticky-note.taped:after {
    top: -7% !important;
    left: 20% !important;
  }
  .postmob{
    right: 0 !important;
    left: 0!important;
    top: 2em !important;
  }
}