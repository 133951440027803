.bg-dp{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../images/BG/image6.png');
  background-repeat: no-repeat;
  background-blend-mode: screen;
  opacity: 0.4;
  
    /* Add a mask to fade out the bottom of the image */
    -webkit-mask-image: linear-gradient(to bottom, black, transparent);
    mask-image: linear-gradient(to bottom, black, transparent);
  
    z-index: -1;
}